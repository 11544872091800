exports.routes = [{
        path: '/',
        name: 'home',
        component: () => import('../views/Home')
    },
    {
        path: '/projekteundevents',
        name: 'projekteevents',
        component: () => import('../views/ProjekteEvents')
    },
    {
        path: '/workshop',
        name: 'workshop',
        meta: {
            sitemap: {
                priority:   0.8,
            }
        },
        component: () => import('../views/Workshop')
    },
    {
        path: '/mieten/:access_type?',
        name: 'mieten',
        meta: {
            sitemap: {
                priority:   0.9,
                slugs: [
                    'company'
                ]
            }
        },
        component: () => import('../views/Mieten.vue')
    },
    {
        path: '/standorte',
        name: 'standorte',
        component: () => import('../views/Standorte.vue')
    },
    {
        path: '/vermietung',
        name: 'vermietung',
        component: () => import('../views/Mieten.vue')
    },
    {
        path: '/schulen',
        name: 'schulen',
        meta: {
            sitemap: {
                priority:   0.8,
            }
        },
        component: () => import('../views/Schulen')
    },
    {
        path: '/shop',
        name: 'shop',
        meta: {
            sitemap: {
                priority:   0.9,
            }
        },
        component: () => import('../views/shop/Shop')
    },
    {
        path: '/shop/:product?',
        name: 'product',
        meta: {
            sitemap: {
                slugs: [
                    '200_dominosteine',
                    '500_dominosteine',
                    '1000_dominosteine',
                    '2000_dominosteine',
                    'kurs_halbtags',
                    'kurs_ganztags',
                    'kurs_2stunden',
                    'tool_set_small',
                    'tool_bruecke',
                    'tool_kamm',
                    'tool_seitenwechsler'
                    //todo immer alle artikel auflisten
                ],
            }
        },
        component: () => import('../views/shop/Product')
    },
    {
        path: '/warenkorb',
        name: 'shopping_cart',
        meta: {
            sitemap: {
                ignoreRoute: true
            }
        },
        component: () => import('../views/shop/ShoppingCart.vue')
    },
    {
        path: '/checkout',
        name: 'checkout',
        meta: {
            sitemap: {
                ignoreRoute: true
            }
        },
        component: () => import('../views/shop/Checkout.vue')
    },
    {
        path: '/tools',
        name: 'instructions',
        component: () => import('../views/shop/InstructionPage.vue')
    },
    {
        path: '/kontakt',
        name: 'kontakt',
        component: () => import('../views/Kontakt.vue')
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        component: () => import('../views/Datenschutz')
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: () => import('../views/Impressum')
    },
    {
        path: '/agb',
        name: 'agb',
        component: () => import('../views/AGB')
    },
    {
        path: '/status/:type',
        name: 'status',
        meta: {
            sitemap: {
                ignoreRoute: true
            }
        },
        component: () => import('../views/Status')
    },
    {
        path: '/portal/:subpage?/:level?/:id?',
        name: 'portal',
        component: () => import('../views/school/PortalHome'),
        meta: {
            sitemap: {
                ignoreRoute: true
            }
        },
    },
    {
        path: '/schulen/portal',
        name: 'portal_legacy',
        component: () => import('../views/school/PortalRedirect'),
        meta: {
            sitemap: {
                ignoreRoute: true
            }
        },
    },
    {
        path: '/pickup/:projectId/:messageId',
        name: 'pickup',
        component: () =>  import('../views/pickup/PickupLanding'),
        meta: {
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '*',
        name: '404',
        component: () => import('../views/Status')
    }
]